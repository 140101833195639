<template>
  <div @touchmove.prevent>
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <div class="bottom">
          <divider class="divider">已加载全部内容</divider>
        </div>
      </div>
    </scroll>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import { Divider } from 'vant'

export default {
  name: 'MyFavorites',
  components: {
    Scroll,
    Divider
  },
  activated () {
    setTimeout(() => {
      this.$refs.scroll.refresh()
    }, 100)
  },
  data () {
    return {
      data: []
    }
  }
}
</script>

<style scoped lang="stylus">
.scroll
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  overflow hidden
  background rgba(255, 255, 255, 1)

  .bottom
    wdith 750px
    height 70px
    padding-top 30px

    .divider
      width 300px
      height 22px
      font-size 22px
      color rgba(153, 153, 153, 1)
      margin 0 auto
      border-color rgba(153, 153, 153, 1)
</style>
